import { Mousewheel, Navigation } from 'swiper/modules';
import Swiper from 'swiper';

const mainDirections = () => {
    const sliders = document.querySelectorAll('.j-slider-directions');

    if (!sliders) {
        return;
    }

    const slidersCache = [];

    if (sliders.length > 0) {
        sliders.forEach((item) => {
            const slider = new Swiper(item, {
                modules      : [Navigation, Mousewheel],
                loop         : false,
                slidesPerView: 'auto',
                grabCursor   : true,
                spaceBetween : 16,
                navigation: {
                    prevEl: '.direction-navigation .prev',
                    nextEl: '.direction-navigation .next'
                },
                mousewheel: {
                    forceToAxis: true,
                    releaseOnEdges: false
                }
            });

            slidersCache.push(slider);
        });
    }
};

export default mainDirections;
