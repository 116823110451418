import appStart from '../../common/scripts/app';
import frontSlider from '../../components/front-slider';
import mainDirections from '../../components/main-directions/index';


document.addEventListener('DOMContentLoaded', () => {
    appStart();
    frontSlider();
    mainDirections();
});
