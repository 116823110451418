// import 'lazysizes';
import { customTabs, debounce, disableHover, fixFullHeight, slideUpDown } from './utils';
import { Header, menuBurger, menuParent } from '../../components/header';
// import GraphModal from 'graph-modal/src/graph-modal.js';
import svg4everybody from 'svg4everybody';


const appStart = () => {
    slideUpDown();
    customTabs();
    fixFullHeight();

    ['resize', 'orientationchange'].forEach((event) => {
        window.addEventListener(event, debounce(fixFullHeight, 20));
    });

    disableHover();
    menuBurger();
    menuParent();
    // new GraphModal();

    new Header().init();
    svg4everybody();
};

export default appStart;
